<template>
  <AppDialog :value="value">
    <template #header>
      <AppDialogHeader>
        <h1>{{ $t('ComponentRegisterLeaveDialog.Header').value }}</h1>
      </AppDialogHeader>
    </template>

    <p>
      {{ $t('ComponentRegisterLeaveDialog.Title').value }}
    </p>

    <template #footer>
      <AppDialogFooter>
        <AppButton
          type="button"
          theme="text"
          analytics-name="register-leave-dialog-yes"
          @click="onClickLeaveRegistration"
        >
          {{ $t('ComponentRegisterLeaveDialog.ButtonYes').value }}
        </AppButton>
        <AppButton
          type="button"
          analytics-name="register-leave-dialog-no"
          @click="$emit('input', false)"
        >
          {{ $t('ComponentRegisterLeaveDialog.ButtonNo').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>


import { AppDialog, AppDialogHeader, AppDialogFooter, AppButton } from '@oen.web.vue2/ui'

import { useAppStore, useI18nStore, useRegistrationStore } from '@galileo/stores'

export default {
  name: 'RegisterLeaveDialog',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { $t } = useI18nStore()
    const registrationStore = useRegistrationStore()
    const appStore = useAppStore()

    const onClickLeaveRegistration = () => {
      // Clear registration store data
      registrationStore.fetchForm()
      // Redirect to public-site/home
      appStore.navigateToXeApp()
    }

    return {
      onClickLeaveRegistration,
      $t,
    }
  },
}
</script>

<style scoped></style>
